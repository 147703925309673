import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth';
// import axios from '@/utils/axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobile: false,
    menuVisivel: false,
    menu: [],
    empresas: [],
    cardapio: [],
    ingredientes: [],
    tipos_preco: [],
    caixas: [],
    caixa_ativo: null,
    empresa_ativa: null,
    loading: true,
    scrollConteudoTop: null,
    notificacoes: [],
    enderecos: [],
    API_BKP: false,
    mobilePortrait:false,
    loadingCardapio: false,
    merchantIds: []
  },
  mutations: {
  },
  actions: {

  },
  modules: {
    auth,
  }
})
