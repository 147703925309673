<template>
  <div id="app">
    <!-- <div class="globalLoading" v-if="$store.state.loading">
      <b-spinner small></b-spinner> carregando...
    </div> -->

    <b-overlay
      :show="$store.state.loading"
      rounded="lg"
      style="height: 100%"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <!-- <b-spinner  variant="secondary"></b-spinner> -->
          <b-spinner />
          <br />
          <!-- We add an SR only text for screen readers -->
          <span style="color: black; margin-left: 5px">carregando...</span>
        </div>
      </template>

      <router-view />
    </b-overlay>
    <IfoodWidget></IfoodWidget>
  </div>
</template>
<script>
import IfoodWidget from "@/components/ifood/ifood-widget.vue";
export default {
  created() {
    this.myEventHandler();
    window.addEventListener("resize", this.myEventHandler);

    window.screen.orientation.addEventListener("change", function (e) {
      this.changeOrientation(e.matches === true ? true : false);
    });
    if (window.matchMedia("(orientation: portrait)").matches) {
      this.changeOrientation("port");
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      this.changeOrientation("land");
    }
  },

  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  components: {
    IfoodWidget,
  },
  mounted() {
    // this.$store.dispatch("auth/atualizarCardapio");
    // this.$msg.onMessage(this.$msg.messaging, (a)=>{
    //   console.log("--------------> msg ", a)
    // })
    // console.log("alerta", process.env.A);
    // this.a = process.env.A
  },
  data() {
    return {
      height: 0,
      // vl:''
    };
  },
  watch: {},
  methods: {
    changeOrientation(portrait) {
      this.$store.state.mobilePortrait = portrait;
    },
    myEventHandler() {
      // this.height = window.innerHeight;
      // document.querySelector(".app").height = this.height;
      if (window.innerWidth > 575 && this.$store.state.menuVisivel) {
        this.$store.state.menuVisivel = false;
      }
      if (window.innerWidth < 575 && !this.$store.state.menuVisivel) {
        // this.$store.state.menuVisivel = true;
      }
      this.$store.state.isMobile = window.innerWidth <= 575;
    },
  },
};
</script>
<style></style>
