

import axios from '@/utils/axios';

const store = async (data) => {
    return (await axios.post("/pedidos/store", data)).data;
}
const get = async (filtro) => {
    const axios = require('../utils/axios').default;
    if (filtro.salao && filtro.salao === 1) {
        return (await axios.post('/pedidos/getMesa', filtro)).data;
    } else {
        return (await axios.post('/pedidos/get', filtro)).data;
    }
}
const getItens = async (cod_pedido) => {
    return (await axios.post('/pedidos/itens/get', { cod_pedido })).data;
}

const storeOnline = async (data) => {
    return (await axios.post('/pedidos/storeOnline', data)).data;
}

const buscarID = async (id, cod_empresa) => {
    return (await axios.post('/pedidos/buscarIDSISTEMA', { id, cod_empresa })).data;
}

const solicitarImpressao = async (tipo, cod_pedido, cod_empresa, cod_caixa) => {
    return (await axios.post('/pedidos/solicitarImpressao', { tipo, cod_pedido, cod_empresa, cod_caixa })).data;
}

const historicoCliente = async (cod_cliente) => {
    return (await axios.post('/clientes/historico', { cod_cliente })).data;
}

const cancelarPedido = async (pedido, ifood = null) => {
    pedido.ifood = ifood;
    return (await axios.post('/pedidos/cancelarPedido', pedido));
}

const getDashboard = async (data) => {
    return (await axios.post('/pedidos/dashboard', data)).data;

}

const getProdutosVendidos = async (dt_i, dt_f, empresas, tipo_entrega, tipo_preco, produtos) => {
    return (await axios.post('/pedidos/produtos_vendidos', { dt_i, dt_f, empresas, tipo_entrega, tipo_preco, produtos })).data;

}
const descancelarPedido = async (pedido) => {
    return (await axios.post('/pedidos/descancelarPedido', pedido));
}

const logPedido = async (pedido) => {
    return ((await axios.post('/pedidos/logPedido', { cod_pedido: pedido.cod_pedido })).data).data;
}

const getRazaoCancelamentoIfood = async (pedido) => {
    return ((await axios.post('/pedidos/razaoCancelamento', { ifood_id: pedido.ifood_id, cod_empresa: pedido.cod_empresa })).data).data;
}

const marcarComoPronto = async (pedido) => {
    return ((await axios.post('/pedidos/marcarComoPronto', { pedido })).data).data;

}

export default {
    store, get, getItens, storeOnline, buscarID, solicitarImpressao, historicoCliente, cancelarPedido, getDashboard, getProdutosVendidos, descancelarPedido,
    logPedido,
    getRazaoCancelamentoIfood,
    marcarComoPronto
}