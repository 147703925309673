<template>
  <div>
    <div
      class="ifood-alert"
      :class="{ show: disputas.length > 0 }"
      @click="openDisputas"
    >
      <img
        src="@/assets/img/ifoodLogo.png"
        alt="ifood"
        width="50"
        height="50"
      />
    </div>
    <b-modal
      id="modal-disputas"
      title="Disputas de Cancelamento"
      size="xl"
      rounded
      class="round-lg"
      hide-footer
      @hidden="closeModalWidget"
    >
      <div class="my-3">
        <b-select
          style="max-width: 200px"
          :options="[
            { value: 'pendente', text: 'Pendente' },
            { value: 'aceito', text: 'Aceito' },
            { value: 'recusado', text: 'Recusado' },
            { value: 'expirado', text: 'Expirado' },
          ]"
          v-model="filterStatus"
          @change="loadDisputas()"
        ></b-select>
      </div>
      <b-table
        ref="tbDisputas"
        striped
        hover
        small
        style="font-size: 12px"
        :items="disputas"
        :fields="[
          { key: 'data', label: 'Data' },
          { key: 'pedido', label: 'Pedido', class: 'text-center' },
          { key: 'pedido.cliente', label: 'Cliente', class: 'text-left' },
          { key: 'prazo', label: 'Expira em', class: 'text-center' },
          { key: 'obs', label: 'Motivo' },
          { key: 'status', label: 'Status', class: 'text-center' },
          { key: 'actions', label: 'Ações', class: 'text-center' },
        ]"
      >
        <template #cell(data)="row">
          <span>{{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}</span>
        </template>
        <template #cell(pedido.cliente)="row">
          <div class="text-left">
            <span>{{ row.item.pedido.dados_cliente.Nome }}</span>
            <div>
              <small>{{ row.item.pedido.dados_cliente.Telefone }}</small>
            </div>
          </div>
        </template>
        <template #cell(pedido)="row">
          <b>{{ row.item.pedido.senha }}</b>
          <div>
            <small>#{{ row.item.pedido.cod_pedido }}</small>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            :variant="
              data.value == 'pendente'
                ? 'warning'
                : data.value == 'expirado'
                ? 'danger'
                : 'success'
            "
            >{{ data.value }}</b-badge
          >
        </template>
        <template #cell(actions)="data">
          <b-button
            size="sm"
            @click="cancelarDisputa(data.item)"
            variant="danger"
            >Aceitar</b-button
          >
          <b-button
            size="sm"
            @click="cancelarDisputa(data.item)"
            variant="primary"
            >Recusar</b-button
          >
          <b-button
            size="sm"
            @click="cancelarDisputa(data.item)"
            variant="danger"
            >Cancelar</b-button
          >
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
var cancelAUDIO = new Audio("http://soundbible.com/grab.php?id=1599&type=mp3");
import DisputasCancelamentoLib from "../../libs/DisputasCancelamentoLib";
import moment from "moment";
export default {
  components: {},
  props: {},

  mounted() {
    let script = document.createElement("script");
    script.src = "https://widgets.ifood.com.br/widget.js";
    script.async = true;
    document.body.appendChild(script);

    this.$nextTick(() => {
      this.loadIfoodWidget();
    });
    this.loadDisputas();
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },
  data() {
    return {
      disputas: [],
      tmr: null,
      tmrToResponse: null,
      filterStatus: "pendente",
    };
  },
  watch: {
    "$store.state.empresa_ativa": {
      deep: true,
      handler() {
        // console.log("loaded");
        this.loadIfoodWidget();
      },
    },
  },
  computed: {},
  methods: {
    closeModalWidget() {
      this.stopTimer();
      this.startTimer();
      this.filterStatus = "pendente";
      this.loadDisputas();
    },
    openDisputas() {
      this.stopTimer();
      this.$bvModal.show("modal-disputas");
    },
    stopTimer() {
      clearInterval(this.tmr);
    },
    startTimer() {
      if (
        this.$store.state.merchantIds &&
        this.$store.state.merchantIds.length > 0
      ) {
        this.tmr = setInterval(() => {
          this.loadDisputas();
        }, 10000);
      }
    },
    async loadDisputas() {
      console.log("loadDisputas");
      let disputas = await DisputasCancelamentoLib.getDisputas({
        status: this.filterStatus,
      });
      this.disputas = [];
      if (disputas.success && disputas.data.length > 0) {
        this.disputas = disputas.data;
        if (this.tmrToResponse) {
          clearInterval(this.tmrToResponse);
        }
        this.tmrToResponse = setInterval(() => {
          this.calculatePrazos();
        }, 1000);
      }
      if (this.filterStatus == "pendente" && this.disputas.length > 0) {
        cancelAUDIO.play();
        setTimeout(() => {
          cancelAUDIO.play();
        }, 2500);
        setTimeout(() => {
          cancelAUDIO.play();
        }, 5500);
      }
      console.log("disputas", this.disputas);
    },
    calculatePrazos() {
      for (let disputa of this.disputas) {
        let final = moment(disputa.created_at).add(5, "minutes");
        if (
          disputa.obj &&
          disputa.obj.metadata &&
          disputa.obj.metadata.expiresAt
        ) {
          final = moment(disputa.obj.metadata.expiresAt);
        }
        // console.log("final", final.format("HH:mm:ss"));
        //moment diff in minutes:seconds format MM:ss
        if (moment().isAfter(final)) {
          disputa.prazo = "expirado";
        } else {
          disputa.prazo = moment
            .utc(moment(final).diff(moment()))
            .format("mm:ss");
          if (this.$refs.tbDisputas && this.$refs.tbDisputas.refresh) {
            this.$refs.tbDisputas.refresh();
          }
        }
      }
    },
    async loadIfoodWidget() {
      if (
        this.$store.state.empresa_ativa &&
        this.$store.state.auth.user.ifood == 1
      ) {
        let integrations = this.$store.state.empresa_ativa.integracoes.filter(
          (x) => x.Sistema == "IFood" && x.Ativo
        );
        let merchantIds = [];
        // console.log("integrations", integrations);
        if (integrations && integrations.length > 0) {
          for (let integration of integrations) {
            if (integration.Adicionais) {
              let findId = integration.Adicionais.find(
                (x) => x.chave == "IdLoja"
              );
              if (findId) {
                merchantIds.push(findId.valor);
              }
            }
          }
          this.$store.state.merchantIds = merchantIds;
          if (merchantIds.length > 0) {
            this.startTimer();
          }
          // console.log("integrations", merchantIds);

          // window.iFoodWidget.init({
          //   widgetId: "36f0d515-232a-4730-b7a9-7a032cb89aff",
          //   merchantIds,
          // });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ifood-alert {
  &.show {
    display: block;
  }
  display: none;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  position: fixed;
  width: 60px;
  height: 60px;
  background-color: red;
  z-index: 1000;
  bottom: 20px;
  left: 50%;
  margin-left: -30px;
  color: white;
  text-align: center;
  //padding-top: 5px;
  border: 1px solid white;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
  border-radius: 50%;
  animation: ifood-alert 0.5s infinite;
  cursor: pointer;
  opacity: 0.95;
  transition: all 0.3s ease;
  &:hover {
    animation: none;
    transform: scale(1.5);
    opacity: 1;
  }
}
//make animation of ifood-alert
@keyframes ifood-alert {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
