<template>
  <div>
    <div
      class="chat_button"
      v-if="!chatVisible"
      @click="chatVisible = true"
      :class="{ 'chatAlert ': qtd > 0 }"
    >
      <i
        class="far fa-comments"
        :class="{ startRing: qtd > 0 }"
      />
      <i
        class="chat_badge"
        :class="{ startRing: qtd > 0 }"
        v-if="qtd > 0"
        >{{ qtd }}</i
      >
    </div>
    <div
      class="chatContain"
      v-if="chatVisible"
    >
      <chat
        :withClose="true"
        @closeChat="closeChat"
      />
    </div>
  </div>
</template>

<script>
import Chat from "./Chat.vue";
var audioNotifi = new Audio(
  "http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3"
);
export default {
  sockets: {
    connect() {
      this.joinRoom();
    },
    naoLido(data) {
      // console.log("nao lido", data);
      this.qtd = data;
      if (this.qtd > 0) {
        audioNotifi.play();
      }
    },
    solicitarNaoLido() {
      this.solicitarNaoLido();
    },
  },
  components: { Chat },
  data() {
    return {
      chatVisible: false,
      user: this.$store.state.auth.user,
      qtd: 0,
    };
  },
  mounted() {
    this.joinRoom();
    this.solicitarNaoLido();
  },
  methods: {
    joinRoom() {
      this.$socket.emit("joinChat", "chat_" + "_user_" + this.user.cod_usuario);
    },
    solicitarNaoLido() {
      this.$socket.emit("solicitaMsgNaoLida", {
        cod_usuario: this.user.cod_usuario,
      });
    },
    closeChat() {
      this.chatVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.chatContain {
  position: fixed;
  bottom: 0px;
  left: 80px;
  width: 50%;
  height: 90%;
  z-index: 99;
  transition: 0.3s all ease;
  box-shadow: 1px 1px 50px;
}
@media only screen and (max-width: 575px) {
  .chat_button {
    top: 10px !important;
    left: 10px !important;
    bottom: none !important;
    z-index: 0 !important;
    position: absolute !important;
  }
  .chatContain {
    width: 100%;
    left: 0px;
    top: 0px;
    bottom: 0px !important;
    height: 100%;
    z-index: 9;
    transition: 0.3s all ease;
  }
}
.chat_button {
  z-index: 999;
  position: fixed;
  left: 90px;
  bottom: 10px;
  background: #000;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 15px;
  padding: 7px;
  transition: 0.3s all ease;
  cursor: pointer;
  .chat_badge {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    font-size: 10px;
    border-radius: 50%;
    padding: 3px;
    padding-left: 1px;
    font-weight: bold;
    transition: 0.3s all ease;
  }
}
.chatAlert {
  border: 1px solid red;
  box-shadow: 1px 1px 50px red;
  filter: brightness(200%);
  font-weight: bolder;
}
.chat_button:hover {
  box-shadow: 5px 5px 46px black;
  .chat_badge {
    // background-color: #000;
  }
}
</style>
