import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Layout from "../views/Layout";
import LayoutCrud from "../views/LayoutCrud";
import store from "../store";
import Menu from "../Menu";
import EntregasLayout from "../views/Entregas/Entregas.vue";
import MesasLayout from "../views/mesas/Mesas.vue";
import Financeiro from "@/views/Financeiro.vue";
import Bancario from "../views/Financeiro/Bancario.vue";

const LoginPage = () => import("@/views/Login");

Vue.use(VueRouter);

const verificaRota = (to, next) => {
  store
    .dispatch("auth/isAuthenticated")
    .then((res) => {
      if (res) {
        let pgs = Menu.reduce((obj, vl) => {
          if (
            vl.permissao.includes("*") ||
            vl.permissao.includes(store.state.auth.user.tipo)
          ) {
            obj.push(vl);
          }
          if (vl.children && vl.children.length > 0) {
            vl.children.map((it) => {
              if (
                it.permissao.includes("*") ||
                it.permissao.includes(store.state.auth.user.tipo)
              ) {
                obj.push(it);
              }
            });
          }
          return obj;
        }, []);
        var pg = pgs.find(
          (x) => x.link == to.path || x.link == to.path.substr(1)
        );
        // console.log("rota", to.path, pg, pgs, store.state.auth.user.tipo);

        store.state.loading = false;

        if (pg != null) {
          next();
        } else {
          next("/status/404");
        }
      } else {
        next("/login");
      }
      // store.state.loading = false;
    })
    .catch(() => {
      store.state.loading = false;
    });
};

const routes = [
  {
    path: "/",
    name: "def",
    beforeEnter(to, from, next) {
      // console.log("entrou");
      if (store.state.menu && store.state.menu.length > 0) {
        next(store.state.menu[0].link);
      } else {
        next("/pedido");
      }
    },
  },
  {
    path: "/",
    component: Layout,
    beforeEnter(to, from, next) {
      // console.log(11111111111111, 111111111111111);
      if (store.state.auth.user && store.state.auth.user.token) {
        console.log(2);
        verificaRota(to, next);
      } else {
        console.log(3);

        store.dispatch("auth/isAuthenticated").then((res) => {
          if (res) {
            console.log(4);
            verificaRota(to, next);
          } else {
            next("/login");
          }
        });
      }
    },
    children: [
      {
        path: "",
        name: "dash",
        beforeEnter(to, from, next) {
          // console.log("entrou");
          next("/pedido");
        },
        component: () => import("@/components/pedido/Pedido.vue"),
      },
      {
        path: "pedido",
        name: "Pedido",
        component: () => import("@/components/pedido/Pedido.vue"),
      },
      {
        path: "/dash",
        name: "Home",
        component: Home,
      },
      {
        path: "/caixas",
        name: "Caixas",
        component: () => import("@/views/Caixa/Caixas.vue"),
      },
      {
        path: "/pedidos",
        name: "Pedidos",
        component: () => import("@/views/Caixa/Pedidos.vue"),
      },
      {
        path: "/notificacoes",
        name: "Notificações",
        component: () => import("@/views/Notificacoes.vue"),
      },
      {
        path: "/disponibilidadeEstoque",
        name: "Disp. Estoque",
        component: () => import("@/views/Estoque/DisponibilidadeEstoque.vue"),
      },
      {
        path: "/fiscal",
        name: "Fiscal",
        component: () => import("@/views/Fiscal/Fiscal.vue"),
        children: [
          {
            path: "home",
            name: "FiscalHome",
            component: () => import("@/views/Fiscal/FiscalHome.vue"),
          },
          {
            path: "notas",
            name: "FiscalNotas",
            component: () => import("@/views/Fiscal/FiscalNotas.vue"),
          },
          {
            path: "configuracoes",
            name: "FiscalConfigs",
            component: () => import("@/views/Fiscal/FiscalConfigs.vue"),
          },
          {
            path: "baixar_xml",
            name: "FiscalBaixarXML",
            component: () => import("@/views/Fiscal/FiscalBaixarXML.vue"),
          },
          {
            path: "inutilizacao",
            name: "FiscalInutilizacao",
            component: () => import("@/views/Fiscal/FiscalInutilizar.vue"),
          },
        ],
      },
      {
        path: "/estoque",
        name: "Estoque",
        component: () => import("@/views/Estoque/Estoque.vue"),
        children: [
          {
            path: "movimentacao",
            name: "Movimentação",
            component: () => import("@/views/Estoque/EstoqueMovimentacao.vue"),
          },
          {
            path: "estoque",
            name: "Estoque",
            component: () => import("@/views/Estoque/EstoqueAtual.vue"),
          },
          {
            path: "produtos",
            name: "Produtos",
            component: () =>
              import("../views/Configuracoes/Cadastros/Produtos.vue"),
          },
          {
            path: "ingredientes",
            name: "Ingredientes",
            component: () =>
              import("../views/Configuracoes/Cadastros/Ingredientes.vue"),
          },
          {
            path: "inventario",
            name: "Inventário",
            component: () => import("@/views/Estoque/Inventario.vue"),
          },
          {
            path: "fornecedores",
            name: "FornecedoresEstoque",
            component: () =>
              import("@/views/Configuracoes/Cadastros/Fornecedores.vue"),
          },
          {
            path: "pedidos",
            name: "PedidosCompra",
            component: () => import("@/views/Estoque/Pedidos.vue"),
          },
        ],
      },

      {
        path: "/financeiro",
        name: "Financeiro",
        component: Financeiro,
        children: [
          {
            path: "/",
            name: "REsumoFinanceiro",
            component: () => import("@/components/Financeiro/ResumoNew.vue"),
          },
          {
            path: "saidas",
            name: "FinanceiroSaidas",
            component: () => import("@/components/Financeiro/Saidas.vue"),
          },
          {
            path: "cp",
            name: "FinanceiroContasPagar",
            component: () => import("@/components/Financeiro/ContasPagar.vue"),
          },
          {
            path: "vales",
            name: "FinanceiroVales",
            component: () => import("@/components/Financeiro/Vales.vue"),
          },
          {
            path: "estoque",
            name: "FinanceiroVales",
            component: () => import("@/components/Financeiro/Estoque.vue"),
          },
          {
            path: "bancario",
            name: "Movimentação Bancária",
            component: Bancario,
            children: [
              {
                path: "resumo",
                name: "Resumo Bancário",
                component: () =>
                  import("@/components/Financeiro/Bancario/Resumo.vue"),
              },
              {
                path: "extrato",
                name: "Extrato Bancário",
                component: () =>
                  import("@/components/Financeiro/Bancario/Extrato.vue"),
              },
              {
                path: "movimentacao",
                name: "Moviemtanção Bancária",
                component: () =>
                  import("@/components/Financeiro/Bancario/Movimentacao.vue"),
              },
            ],
          },

          {
            path: "fiscal",
            name: "Fiscal",
            component: () => import("@/views/Financeiro/Fiscal.vue"),
          },
          {
            path: "movimentacao-financeira",
            name: "Movimentação Financeira",
            component: () =>
              import(
                "@/components/Relatorios/RelatorioMovimentacaoFinanceira.vue"
              ),
          },
        ],
      },
      {
        path: "/mesas",
        name: "Mesas",
        component: MesasLayout,
        beforeRouteLeave(to, from, next) {
          // ...
          next(false);
        },
        children: [
          {
            path: "geral",
            name: "VisaoGeralMesas",
            component: () => import("@/views/mesas/MesasGeral.vue"),
          },
          {
            path: "caixas",
            name: "MesaCaixas",
            component: () => import("@/views/mesas/MesasCaixa.vue"),
          },
          {
            path: "cardapio",
            name: "MesaCardapio",
            component: () => import("@/views/mesas/MesasCardapio.vue"),
          },
          {
            path: "reservas",
            name: "MesaReservas",
            component: () => import("@/views/mesas/MesaNewReservas.vue"),
          },
        ],
      },
      {
        path: "entregas",
        name: "Entregas",
        component: EntregasLayout,
        children: [
          {
            path: "geral",
            name: "VisaoGeral",
            component: () => import("@/views/Entregas/EntregasVisaoGeral.vue"),
          },
          {
            path: "motos",
            name: "Motos",
            component: () => import("@/views/Entregas/EntregasMotos.vue"),
          },
          {
            path: "pendentes",
            name: "EntregasPendentes",
            component: () => import("@/views/Entregas/EntregasLista.vue"),
            props: {
              tipo: "pendentes",
            },
          },
          {
            path: "rota",
            name: "EntregasRota",
            component: () => import("@/views/Entregas/EntregasLista.vue"),
            props: {
              tipo: "rota",
            },
          },
          {
            path: "concluidas",
            name: "EntregasConcluidas",
            component: () => import("@/views/Entregas/EntregasLista.vue"),
            props: {
              tipo: "concluidas",
            },
          },
          {
            path: "buscar",
            name: "EntregasBuscar",
            component: () => import("@/views/Entregas/EntregasLista.vue"),
            props: {
              tipo: "buscar",
            },
          },
        ],
      },
      {
        path: "/ifood",
        name: "Ifood",
        component: () => import("@/views/ifood/index.vue"),
        children: [
          {
            path: "/",
            component: () => import("@/views/ifood/dashboard.vue"),
          },
        ],
      },
      {
        path: "/relatorios",
        name: "Relatorios",
        component: () => import("@/views/Relatorios"),
        children: [
          {
            path: "consumo-estoque",
            name: "ConsumoEstoque",
            component: () =>
              import(
                "@/components/Ingredientes/RelatorioDeGastos/ResumoNew.vue"
              ),
          },
          {
            path: "pedidos",
            name: "GestaoPedidos",
            component: () =>
              import("@/components/pedido/RelatorioGestaoPedidos.vue"),
          },
          {
            path: "produtos-vendidos",
            name: "ProdutosVendidos",
            component: () =>
              import("@/components/Relatorios/ProdutosVendidos.vue"),
          },
          {
            path: "acessos-usuario",
            name: "AcessoSistema",
            component: () =>
              import("@/components/Relatorios/AcessosUsuario.vue"),
          },

          //acessos-usuario
        ],
      },
      {
        path: "configuracoes",
        component: LayoutCrud,
        name: "configs",
        children: [
          {
            path: "impressoras",
            name: "ConfigsImpressoras",
            component: () => import("../views/Configuracoes/Impressoras.vue"),
          },
          {
            path: "modelos-impressao",
            name: "ConfigsImpressorasModelos",
            component: () =>
              import("../views/Configuracoes/ModelosImpressao.vue"),
          },
          {
            path: "clientes",
            name: "CadastroClientes",
            component: () =>
              import("../views/Configuracoes/Cadastros/Clientes.vue"),
          },
          {
            path: "Usuarios",
            name: "CadastroUsuarios",
            component: () =>
              import("../views/Configuracoes/Cadastros/Usuarios.vue"),
          },
          {
            path: "Ingredientes",
            name: "CadastroIngredientes",
            component: () =>
              import("../views/Configuracoes/Cadastros/Ingredientes.vue"),
          },
          {
            path: "Empresas",
            name: "CadastroEmpresas",
            component: () =>
              import("../views/Configuracoes/Cadastros/Empresas.vue"),
          },
          {
            path: "Fornecedores",
            name: "CadastroFornecedores",
            component: () =>
              import("../views/Configuracoes/Cadastros/Fornecedores.vue"),
          },
          {
            path: "Mesas",
            name: "CadastroMesas",
            component: () =>
              import("../views/Configuracoes/Cadastros/Mesas.vue"),
          },
          {
            path: "grupo_produtos",
            name: "CadastroGrupoDeProdutos",
            component: () =>
              import("../views/Configuracoes/Cadastros/GruposDeProdutos.vue"),
          },
          {
            path: "produtos",
            name: "CadastroProdutos",
            component: () =>
              import("../views/Configuracoes/Cadastros/Produtos.vue"),
          },
          {
            path: "combo-produtos",
            name: "ComboProdutos",
            component: () =>
              import("../views/Configuracoes/Cadastros/ComboProdutos.vue"),
          },
          {
            path: "centros_de_custo",
            name: "centrosDeCusto",
            component: () =>
              import("../views/Configuracoes/Cadastros/CentrosDeCusto.vue"),
          },
          {
            path: "plano_contas",
            name: "planoDeContas",
            component: () =>
              import(
                "../views/Configuracoes/Cadastros/PlanoContas/PlanoContas.vue"
              ),
          },
          {
            path: "carteiras",
            name: "carteiras",
            component: () =>
              import("../views/Configuracoes/Financeiro/Carteiras.vue"),
          },
          {
            path: "tipo_historico",
            name: "tipo_historico",
            component: () =>
              import("../views/Configuracoes/Financeiro/TipoHistorico.vue"),
          },
        ],
      },
    ],
  },

  {
    path: "/login",
    name: "Login",
    beforeEnter(to, from, next) {
      store.state.loading = false;

      if (store.state.auth.user && store.state.auth.user.token) {
        next("./");
      } else {
        //next("./");

        store
          .dispatch("auth/isAuthenticated")
          .then((res) => {
            if (res) {
              store.state.loading = false;

              next("./");
            } else {
              store.state.loading = false;

              next();
            }
          })
          .catch(() => {
            store.state.loading = false;
            next();
          });
      }
    },
    component: LoginPage,
  },

  {
    path: "/status/404",
    name: "Status404",
    component: () => import("../views/status/404.vue"),
  },

  {
    path: "/logout",
    name: "Logout",
    component: () => {
      store.dispatch("auth/logout").then((q) => {
        if (q) {
          window.location.href = "./";
        }
      });
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
