import axios from "@/utils/axios";

const getDisputas = async (filters) => {
  let ret = null;
  try {
    ret = (await axios.post("/disputa_cancelamento/get", filters))
      .data;
  } catch (err) {
    console.log("error on get contas a pagar", err);
  }

  return ret;
};

export default {
  getDisputas,
};
