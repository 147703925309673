var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"ifood-alert",class:{ show: _vm.disputas.length > 0 },on:{"click":_vm.openDisputas}},[_c('img',{attrs:{"src":require("@/assets/img/ifoodLogo.png"),"alt":"ifood","width":"50","height":"50"}})]),_c('b-modal',{staticClass:"round-lg",attrs:{"id":"modal-disputas","title":"Disputas de Cancelamento","size":"xl","rounded":"","hide-footer":""},on:{"hidden":_vm.closeModalWidget}},[_c('div',{staticClass:"my-3"},[_c('b-select',{staticStyle:{"max-width":"200px"},attrs:{"options":[
          { value: 'pendente', text: 'Pendente' },
          { value: 'aceito', text: 'Aceito' },
          { value: 'recusado', text: 'Recusado' },
          { value: 'expirado', text: 'Expirado' },
        ]},on:{"change":function($event){return _vm.loadDisputas()}},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1),_c('b-table',{ref:"tbDisputas",staticStyle:{"font-size":"12px"},attrs:{"striped":"","hover":"","small":"","items":_vm.disputas,"fields":[
        { key: 'data', label: 'Data' },
        { key: 'pedido', label: 'Pedido', class: 'text-center' },
        { key: 'pedido.cliente', label: 'Cliente', class: 'text-left' },
        { key: 'prazo', label: 'Expira em', class: 'text-center' },
        { key: 'obs', label: 'Motivo' },
        { key: 'status', label: 'Status', class: 'text-center' },
        { key: 'actions', label: 'Ações', class: 'text-center' },
      ]},scopedSlots:_vm._u([{key:"cell(data)",fn:function(row){return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(row.item.created_at,"DD/MM/YYYY HH:mm")))])]}},{key:"cell(pedido.cliente)",fn:function(row){return [_c('div',{staticClass:"text-left"},[_c('span',[_vm._v(_vm._s(row.item.pedido.dados_cliente.Nome))]),_c('div',[_c('small',[_vm._v(_vm._s(row.item.pedido.dados_cliente.Telefone))])])])]}},{key:"cell(pedido)",fn:function(row){return [_c('b',[_vm._v(_vm._s(row.item.pedido.senha))]),_c('div',[_c('small',[_vm._v("#"+_vm._s(row.item.pedido.cod_pedido))])])]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":data.value == 'pendente'
              ? 'warning'
              : data.value == 'expirado'
              ? 'danger'
              : 'success'}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.cancelarDisputa(data.item)}}},[_vm._v("Aceitar")]),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.cancelarDisputa(data.item)}}},[_vm._v("Recusar")]),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.cancelarDisputa(data.item)}}},[_vm._v("Cancelar")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }