<template>
  <div>
    <b
      ><b-icon-cash-coin /> Financeiro periodo
      {{ date.start | moment("DD/MM") }}
      até
      {{ date.end | moment("DD/MM") }}
    </b>

    <div class="row mb-1 mt-2">
      <div class="col-12 col-md-2 px-1">
        <div class="small-box bg-success click" @click="openResumo('Loja')">
          <div class="inner">
            <div v-if="loading">
              <b-skeleton height="78px" />
            </div>
            <div v-else>
              <h4>
                <b>{{ resumo.totalLoja | currencyMask }}</b>
              </h4>

              <p>LOJA</p>
            </div>
          </div>
          <div class="icon">
            <i class="fa fa-home" style="font-size: 40px"></i>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-2 px-1">
        <div class="small-box bg-success click" @click="openResumo('Ifood')">
          <div class="inner">
            <div v-if="loading">
              <b-skeleton height="78px" />
            </div>
            <div v-else>
              <h4>
                <b>{{ resumo.totalIfood | currencyMask }}</b>
              </h4>

              <p>IFOOD</p>
            </div>
          </div>
          <div class="icon">
            <i class="fa fa-project-diagram" style="font-size: 40px"></i>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-2 px-1">
        <div
          class="small-box bg-success click"
          @click="openResumo('Outras Receitas')"
        >
          <div class="inner">
            <div v-if="loading">
              <b-skeleton height="78px" />
            </div>
            <div v-else>
              <h4>
                <b>{{ resumo.totalOutrasReceitas | currencyMask }}</b>
              </h4>

              <p>Outras Receitas</p>
            </div>
          </div>
          <div class="icon">
            <i class="fa fa-hand-holding-usd" style="font-size: 40px"></i>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-2 px-1">
        <div class="small-box bg-danger click" @click="openResumo('Despesas')">
          <div class="inner">
            <div v-if="loading">
              <b-skeleton height="78px" />
            </div>
            <div v-else>
              <h4>
                <b>{{ resumo.totalDespesas | currencyMask }}</b>
              </h4>

              <p>Despesas / Custos</p>
            </div>
          </div>
          <div class="icon">
            <i class="fa fa-shopping-bag" style="font-size: 40px"></i>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-2 px-1">
        <div class="small-box bg-info click" @click="openResumo('Total')">
          <div class="inner">
            <div v-if="loading">
              <b-skeleton height="78px" />
            </div>
            <div v-else>
              <h4>
                <b>{{ resumo.total | currencyMask }}</b>
              </h4>

              <p>Total Periodo</p>
            </div>
          </div>
          <div class="icon">
            <i class="fa fa-wallet" style="font-size: 40px"></i>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-2 px-1 pl-0">
        <div
          class="small-box xs bg-warning click mb-0"
          style="height: 40px" 
        >
          <div class="inner">
            <div v-if="loading">
              <b-skeleton height="20px" />
            </div>
            <div
              v-else
              class="text-primary d-flex justify-content-between"
              style="font-size: 12px"
            >
              <small>Á Pagar</small>
              <span class="mb-0 mr-1">
                <b>{{ resumo.despesaFutura | currencyMask }}</b>
              </span>
            </div>
          </div>
        </div>
        <div
          class="small-box xs bg-primary click mt-2"
          style="height: 40px" 
        >
          <div class="inner">
            <div v-if="loading">
              <b-skeleton height="20px" />
            </div>
            <div
              v-else
              class="d-flex justify-content-between"
              style="font-size: 11px"
            >
              <small>Saldo Futuro</small>
              <span class="mb-0 mr-1">
                <b>{{ resumo.saldoFuturo | currencyMask }}</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-open-resumo"
      class="modal-full"
      modal-class="modal-full m-0 p-0"
      body-class="px-0"
      size="xl"
      hide-title
      hide-footer
      hide-header
    >
      <h3 class="d-flex justify-content-between align-end px-3">
        <b-icon-arrow-left
          style="cursor: pointer"
          @click="$bvModal.hide('modal-open-resumo')"
        ></b-icon-arrow-left>
        {{ tipoDetalhe }}
        <small>
          <b>
            {{
              tipoDetalhe == "Loja"
                ? resumo.totalLoja
                : tipoDetalhe == "Ifood"
                ? resumo.totalIfood
                : tipoDetalhe == "Despesas"
                ? resumo.totalDespesas
                : resumo.total | currencyMask
            }}
          </b>
        </small>
      </h3>
      <hr />
      <home-financeiro-details
        :detalheResumo="detalheResumo"
      ></home-financeiro-details>
    </b-modal>

    <b-modal
      id="modal-open-financeiro"
      class="modal-full"
      modal-class="modal-full"
      body-class="px-0"
      size="xl"
      hide-title
      hide-footer
      hide-header
    >
      <h3 class="d-flex justify-content-between align-end px-3">
        <b-icon-arrow-left
          style="cursor: pointer"
          @click="$bvModal.hide('modal-open-resumo')"
        ></b-icon-arrow-left>
        {{ tipoDetalhe }}
        <small>
          <b>
            {{
              tipoDetalhe == "Receitas"
                ? this.receitas
                : tipoDetalhe == "Despesas"
                ? this.despesas
                : tipoDetalhe == "Carteiras"
                ? this.totalCarteiras
                : 0 | currencyMask
            }}
          </b>
        </small>
      </h3>
      <hr />

      <home-financeiro-details
        :detalheResumo="detalheResumo"
      ></home-financeiro-details>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import FiscalLib from "../libs/FinanceiroLib";
import HomeFinanceiroDetails from "./HomeFinanceiroDetails.vue";
import ContasPagarLib from "../libs/ContasPagarLib";
export default {
  components: { HomeFinanceiroDetails },
  props: {
    date: {
      type: Object,
    },
    empresa: {
      type: Object,
    },
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      loading: false,
      resumo: {
        totalLoja: 0,
        totalIfood: 0,
        totalOutrasReceitas: 0,
        totalDespesas: 0,
        total: 0,
        items: [],
      },
      tipoDetalhe: "",
      detalheResumo: [],
      resumoFinanceiro: null,
    };
  },
  watch: {
    penultimaSemana: {
      handler: function () {
        this.carregar();
      },
      deep: true,
    },
  },
  computed: {
    penultimaSemana() {
      let firstday = moment(this.date.start)
        .subtract(1, "weeks")
        .startOf("week")
        .add(1, "day");
      let lastday = moment(this.date.start)
        .subtract(1, "weeks")
        .endOf("week")
        .add(1, "day");
      console.log(
        "d",
        firstday.format("YYYY-MM-DD"),
        lastday.format("YYYY-MM-DD")
      );
      return {
        firstday: firstday.toDate(),
        lastday: lastday.toDate(),
      };
    },
    ultimaSemana() {
      let date = new Date();
      let first = date.getDate() - date.getDay() + 1;
      let last = first + 6;
      let firstday = new Date(date.setDate(first));
      let lastday = new Date(date.setDate(last));
      return {
        firstday,
        lastday,
      };
    },
    totalCarteiras() {
      return this.resumoFinanceiro && this.resumoFinanceiro.carteiras
        ? this.resumoFinanceiro.carteiras.reduce((ret, vl) => {
            ret += vl.saldo;
            return ret;
          }, 0)
        : 0;
    },
    receitas() {
      return this.resumoFinanceiro && this.resumoFinanceiro.movimentacao
        ? this.resumoFinanceiro.movimentacao
            .filter((x) => x.tipo == 1)
            .reduce((ret, vl) => {
              ret += vl.valor;
              return ret;
            }, 0)
        : 0;
    },
    despesas() {
      return this.resumoFinanceiro && this.resumoFinanceiro.movimentacao
        ? this.resumoFinanceiro.movimentacao
            .filter((x) => x.tipo == -1)
            .reduce((ret, vl) => {
              ret += vl.valor;
              return ret;
            }, 0)
        : 0;
    },
  },
  methods: {
    async carregar() {
      this.loading = true;
      this.resumo = {};

      console.log("1", this.penultimaSemana);
      let result = await FiscalLib.getResumoSemana({
        start: moment(this.date.start).format("YYYY-MM-DD"),
        end: moment(this.date.end).format("YYYY-MM-DD"),
        cod_empresa: this.empresa ? this.empresa.cod_empresa : -1,
      });
      console.log("result", result);
      let resultado = result.data;
      let totalLoja = resultado.reduce((ret, vl) => {
        if (vl.planoResumoGrupo == "Vendas Loja") ret += vl.valor;
        return ret;
      }, 0);
      let totalIfood = resultado.reduce((ret, vl) => {
        if (vl.planoResumoGrupo == "Vendas Ifood") ret += vl.valor;
        return ret;
      }, 0);
      let totalOutrasReceitas = resultado.reduce((ret, vl) => {
        if (vl.planoResumoGrupo == "Outras Receitas") ret += vl.valor;
        return ret;
      }, 0);
      let totalDespesas = resultado.reduce((ret, vl) => {
        if (vl.planoResumoGrupo == "Despesas") ret += vl.valor;
        return ret;
      }, 0);

      this.resumo = {
        totalLoja,
        totalIfood,
        totalDespesas,
        totalOutrasReceitas,
        total:
          (totalLoja || 0) +
          (totalIfood || 0) +
          (totalOutrasReceitas || 0) +
          (totalDespesas || 0),
        items: resultado,
      };
      let getDespesasFuturas = await ContasPagarLib.getContasPagar({
        dt_i: moment(this.date.start).format("YYYY-MM-DD"),
        dt_f: moment(this.date.end).format("YYYY-MM-DD"),
        cod_empresa: [this.empresa ? this.empresa.cod_empresa : -1],
        vencidos: false,
        pagos: false,
        vencendo: true,
        situacao: "Aberto",
      });
      console.log("desp", getDespesasFuturas);
      if (getDespesasFuturas.success) {
        this.resumo.despesaFutura = getDespesasFuturas.data.reduce(
          (ret, vl) => {
            ret += vl.vl_parcela - (vl.valor_pago||0);
            return ret;
          },
          0
        );
      }

      this.resumo.saldoFuturo = this.resumo.total - this.resumo.despesaFutura;

      console.log("result", this.resumo);

      let resumoFinanceiro = await FiscalLib.getResumoFinanceiroNew({
        start: this.date.start,
        end: this.date.end,
      });

      console.log("resumoFinanceiro", resumoFinanceiro);
      this.resumoFinanceiro = resumoFinanceiro.data;

      this.loading = false;
    },
    openResumo(tipo) {
      this.tipoDetalhe = tipo;
      if (tipo == "Loja") {
        this.detalheResumo = this.resumo.items.filter(
          (x) => x.planoResumoGrupo == "Vendas Loja"
        );
      }
      if (tipo == "Ifood") {
        this.detalheResumo = this.resumo.items.filter(
          (x) => x.planoResumoGrupo == "Vendas Ifood"
        );
      }
      if (tipo == "Despesas") {
        this.detalheResumo = this.resumo.items.filter(
          (x) => x.planoResumoGrupo == "Despesas"
        );
      }
      if (tipo == "Outras Receitas") {
        this.detalheResumo = this.resumo.items.filter(
          (x) => x.planoResumoGrupo == "Outras Receitas"
        );
      }
      if (tipo == "Total") {
        this.detalheResumo = this.resumo.items;
      }
      this.detalheResumo = this.makeGroups(this.detalheResumo);
      console.log("tipo", tipo, this.detalheResumo);
      this.$bvModal.show("modal-open-resumo");
    },
    openFinanceiro(tipo) {
      this.tipoDetalhe = tipo;

      if (tipo == "Receitas") {
        this.detalheResumo = this.makeGroups(
          this.resumoFinanceiro.movimentacao.filter((x) => x.tipo == 1)
        );
      }
      if (tipo == "Despesas") {
        this.detalheResumo = this.makeGroups(
          this.resumoFinanceiro.movimentacao.filter((x) => x.tipo == -1)
        );
      }
      if (tipo == "Carteiras") {
        this.detalheResumo = this.resumoFinanceiro.carteiras;
      }
      this.$bvModal.show("modal-open-financeiro");
    },
    makeGroups(data) {
      return data.reduce((ret, vl) => {
        let has = ret.find((x) => x.planoGrupo == vl.planoGrupo);
        if (has) {
          has.valor += vl.valor;
        } else {
          ret.push({
            planoGrupo: vl.planoGrupo,
            valor: vl.valor,
            show: false,
            tipo: vl.tipo,
            subgrupos: data
              .filter((x) => x.planoGrupo == vl.planoGrupo)
              .reduce((r2, v2) => {
                let has = r2.find((x) => x.planoSubgrupo == v2.planoSubgrupo);
                if (has) {
                  //has.itens.push(v2);
                  has.valor += v2.valor;
                } else {
                  r2.push({
                    planos: data
                      .filter(
                        (x) =>
                          x.planoGrupo == vl.planoGrupo &&
                          x.planoSubgrupo == v2.planoSubgrupo
                      )
                      .reduce((r2, v2) => {
                        let has = r2.find(
                          (x) => x.planoContas == v2.planoContas
                        );
                        if (has) {
                          has.itens.push(v2);
                          has.valor += v2.valor;
                        } else {
                          r2.push({
                            itens: [{ ...v2, show: false }],
                            valor: v2.valor,
                            planoContas: v2.planoContas,
                            show: false,
                            tipo: v2.tipo,
                          });
                        }
                        return r2;
                      }, []),
                    //itens: [{ ...v2, show: false }],
                    valor: v2.valor,
                    planoSubgrupo: v2.planoSubgrupo,
                    show: false,
                    tipo: v2.tipo,
                  });
                }
                return r2;
              }, []),
          });
        }
        return ret;
      }, []);
    },
  },
};
</script>

<style lang="sass" scoped>

@import "@/assets/scss/home.scss"
</style>
